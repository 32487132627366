import React from 'react'

import './Styles/Scroller.css'

interface ScrollerState {
    innerDivStyle: {}
}

interface ScrollerProps {
    onChange?: (offset: number) => void
    unitCount?: number,
    className?: string
}

class Scroller extends React.Component<ScrollerProps> {
    state: ScrollerState;
    basePosX: number;
    lastOffset: number;
    innerRef: React.RefObject<HTMLDivElement>;
    outerRef: React.RefObject<HTMLDivElement>;
    innerWidth: number;
    outerWidth: number = 0;
    offset: number;
    finalOffset: number = 0;
    rollCount: number;
    unitWidth: number = 1
    constructor(props: any) {
        super(props);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.state = {
            innerDivStyle: { 'marginLeft': 0 }
        }
        this.basePosX = 0;
        this.innerWidth = 0;
        this.lastOffset = 0;
        this.innerRef = React.createRef()
        this.outerRef = React.createRef()
        this.offset = 0;
        this.rollCount = 0
    }

    handleMouseMove(e: MouseEvent) {
        let newX = e.clientX - this.basePosX;

        if (this.lastOffset !== newX) {
            let margin = 0
            let offset = this.offset + newX
            if (offset > 0) {
                margin = -this.outerWidth + ((this.offset + newX) % this.outerWidth);
            } else {
                margin = ((this.offset + newX) % this.outerWidth);
            }
            this.setState({
                innerDivStyle: { 'marginLeft': (margin), width: this.outerWidth * 2 }
            })
            this.lastOffset = newX;
            if (this.props.onChange) {
                const offset = this.offset + this.lastOffset + (this.rollCount * this.innerWidth);

                if (this.props.unitCount === null) {
                    this.props.onChange(-offset);
                } else {
                    let finalOffset = Math.round(offset / this.unitWidth);
                    if (this.finalOffset !== finalOffset) {
                        this.finalOffset = finalOffset;
                        this.props.onChange(-this.finalOffset);
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.outerWidth = this.outerRef!.current!.offsetWidth;

        this.setState({
            innerDivStyle: {
                'marginLeft': 0,
                width: this.outerWidth * 2
            }
        })

    }

    handleMouseUp(e: MouseEvent) {
        this.offset += this.lastOffset;
        document.removeEventListener('mousemove', this.handleMouseMove, false);
        document.removeEventListener('mouseup', this.handleMouseUp, false);
    }

    handleMouseDown(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        this.basePosX = e.clientX;

        if (this.props.unitCount) {
            this.unitWidth = this.outerWidth / this.props.unitCount;
        }

        document.addEventListener('mousemove', this.handleMouseMove, false);
        document.addEventListener('mouseup', this.handleMouseUp, false);
    }

    render(): React.ReactNode {
        return <div
            className={`bl-scroller ${this.props.className}`}
            onMouseDown={(e) => { this.handleMouseDown(e) }}
            ref={this.outerRef}
        >
            <div id='bl-scroller-w1' className='bl-scroller-w' style={this.state.innerDivStyle} ref={this.innerRef}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div >
    }
}



export default Scroller;