import React from "react";
import { AutoComplete } from "./AutoComplete";
import Scroller from "./Scroller";
import "./Styles/TimezoneConverter.css"
import { Clock } from "./Clock";
import { v4 as uuidv4 } from 'uuid';
import { ContentScroller } from './ContentScroller'
import { response } from "express";
export interface IDictionary {
    [index: string]: string;
}
export interface IControlConfig {
    TypeLabel: string
    ControlCode: string
    DataLabelField: string
    DataValueField: string
    Visible: boolean
    DataSource: []
}

interface TimeZoneConverterState {
    offset: number,
    locations: Location[],
    offsetLabel: string,
    time: Date
}

interface CitiData {
    shortName: string,
    name: string,
    timezone: string
}

type ClockTrigger = () => void;

/*

todo

use url to set cities
colr doesn't change automatically

minute changes at 59 sec

alert user for offset option

clock scroller update on window resize
when adding new, maybe scroll to end with animation
remove city highlight
recent selection
cache results in database
ios close button doesn't work
clear all
type local time
save user selection


*/

class TimeZoneConverter extends React.Component {
    state: TimeZoneConverterState;
    timezones: string[]
    cityList: CitiData[]
    clockUpdate: ClockTrigger[] = [];
    timer?: NodeJS.Timer

    constructor(props: any) {
        super(props);
        const cookieArray = document.cookie.split(';');
        let value = null;
        cookieArray.forEach((v: string) => {
            var cookieItem = v.trim().split('=');
            if (cookieItem[0] === 'tzc_loc') {
                value = cookieItem[1];
            }
        });
        let loc: Location[] = [];
        try {
            loc = value ? eval(value) : [];
        } catch (e) {

        }

        loc = loc.filter(x => x.isMounted && x.isVisible)

        if (loc.length === 0) {
            const call = async () => {
                const res = await fetch('https://comparetimezones2.azurewebsites.net/getcountry')
                const result = await res.json();
                const d1: any = result;
                loc.push(new Location(d1.city_name, d1.time_zone_info.olson))
                this.setState({ locations: loc })
                this.saveCookie(loc);
            }

            call();
        }


        this.state = {
            offset: 0,
            locations: loc,
            offsetLabel: '00:00',
            time: new Date()
        }
        this.handleScrollerChange = this.handleScrollerChange.bind(this);
        this.handleAnimationEnd = this.handleAnimationEnd.bind(this);

        this.timezones = []
        this.cityList =
            [
                {
                    "shortName": "Budapest",
                    "name": "Budapest, Hungary",
                    "timezone": "Europe/Budapest"
                },
                {
                    "shortName": "Cape Town",
                    "name": "Cape Town, South Africa",
                    "timezone": "Africa/Johannesburg"
                },
                {
                    "shortName": "Delhi",
                    "name": "Delhi, India",
                    "timezone": "Asia/Calcutta"
                },
                {
                    "shortName": "London",
                    "name": "London, UK",
                    "timezone": "Europe/London"
                },
                {
                    "shortName": "Los Angeles",
                    "name": "Los Angeles, CA, USA",
                    "timezone": "America/Los_Angeles"
                },
                {
                    "shortName": "Mexico City",
                    "name": "Mexico City, CDMX, Mexico",
                    "timezone": "America/Mexico_City"
                },
                {
                    "shortName": "New York",
                    "name": "New York, NY, USA",
                    "timezone": "America/New_York"
                },
                {
                    "shortName": "Paris",
                    "name": "Paris, France",
                    "timezone": "Europe/Paris"
                },
                {
                    "shortName": "São Paulo",
                    "name": "São Paulo, State of São Paulo, Brazil",
                    "timezone": "America/Sao_Paulo"
                },
                {
                    "shortName": "Shanghai",
                    "name": "Shanghai, China",
                    "timezone": "Asia/Shanghai"
                },
                {
                    "shortName": "Sydney",
                    "name": "Sydney NSW, Australia",
                    "timezone": "Australia/Sydney"
                },
                {
                    "shortName": "Tokyo",
                    "name": "Tokyo, Japan",
                    "timezone": "Asia/Tokyo"
                }
            ]


    }


    componentDidMount() {
        if (this.timer == null) {
            this.timer = setInterval(() => {
                this.clockUpdate.forEach((a) => {
                    a();
                })
                this.setState({
                    time: new Date()
                })
            }, 1000)
        }
    }


    zeroPad(num: number, length: number) {
        let text = num + '';
        while (text.length < length) {
            text = '0' + text;
        };
        return text;
    }
    displayTime(duration: number) {
        duration = Math.round(duration);
        var negative = false;
        if (duration < 0) {
            negative = true;
            duration = Math.abs(duration);
        }


        var day = Math.floor(duration / 1440);
        duration = duration - (day * 1440);
        var daystring = '';
        if (day > 0) daystring = day + 'd '
        return (negative ? '-' : '+') + daystring + this.zeroPad(Math.floor(duration / 60), 2) + ':' + this.zeroPad((duration - (Math.floor(duration / 60) * 60)), 2);

    }

    handleScrollerChange(offset: number) {
        this.setState({ offset: -offset, offsetLabel: this.displayTime(-offset) })

    }

    handleCityClick(d: CitiData) {
        const loc = this.state.locations;
        loc.push(new Location(d.name, d.timezone))
        this.setState({ locations: loc })
        this.saveCookie(loc);
    }

    handleTimeZoneChange(data: IDictionary) {

        const call = async () => {

            const res = await fetch(process.env.REACT_APP_API_URI + 'api/get-city?place_id=' + data.Value)
            const result = await res.json();
            const lat = result.result.geometry.location.lat;
            const lng = result.result.geometry.location.lng;
            const res2 = await fetch(process.env.REACT_APP_API_URI + 'api/get-timezone?lat=' + lat + '&lng=' + lng)
            const result2 = await res2.json();

            const loc = this.state.locations;
            loc.push(new Location(data.Label, result2.timeZoneId))

            this.setState({ locations: loc })
            this.saveCookie(loc);
        }
        call();



    }

    saveCookie(loc: Location[]) {
        const expdate = new Date();
        expdate.setDate(expdate.getDate() + 30);
        const c_value = JSON.stringify(loc) + "; expires=" + expdate.toUTCString() + "; path=/";
        var s = "tzc_loc=" + c_value
        document.cookie = s;
    }

    handleClick(e: React.MouseEvent) {
        e.preventDefault();
        var t = e.target as HTMLElement
        if (t.nodeName === 'path') {
            let id = t.getAttribute('data-id');
            if (id != null) {

                const loc = this.state.locations.find(x => x.id === id);
                if (loc) {
                    loc.isVisible = false;
                    this.setState({
                        locations: this.state.locations
                    })

                    this.saveCookie(this.state.locations);
                }
            }
        }
    }

    handleOnExited() {

    }

    handleAnimationEnd(e: React.AnimationEvent) {
        var t = e.target as HTMLElement

        let id = t.getAttribute('data-id');
        if (id != null) {
            const loc = this.state.locations.find(x => x.id === id);
            if (loc) {
                if (loc.isVisible === false) {
                    loc.isMounted = false;
                    this.setState({
                        locations: this.state.locations
                    })

                    this.saveCookie(this.state.locations);
                }
            }
        }
    }

    render() {
        const time = new Date(this.state.time.getTime());
        time.setMinutes(this.state.time.getMinutes() + this.state.offset)

        const ds: {}[] = [];
        this.timezones.forEach((x) => {
            ds.push({ Value: x, Label: x })
        })

        const config: IControlConfig = {
            DataSource: ds as [],
            DataValueField: 'Value',
            DataLabelField: 'Label',
            TypeLabel: '',
            ControlCode: 'TimeZone',
            Visible: true
        }

        const locMapping: { [key: string]: Location } = {}

        const { locations } = this.state;

        const cities: React.ReactNode[] = []
        locations.forEach((l) => {
            if (l.isVisible) {
                locMapping[l.name] = l;
            }
        })

        this.cityList.forEach((x, i) => {
            if (locMapping[x.name] == null) {
                cities.push(<button
                    type="button"
                    key={i}
                    className="c-button"
                    onClick={() => { this.handleCityClick(x) }}
                >
                    {x.shortName}
                </button>)
            }

        })

        return <div className="bf-tzc">
            <svg className="bf-tzc__skyline1" viewBox="0 0 143 28">
                <polygon fill="white" points="143.51 35.74 99.15 35.74 92.59 35.74 92.59 35.74 62.14 35.74 54.8 35.74 18.43 35.74 18.43 35.73 0.11 35.73 0.13 31.27 2.92 31.27 2.92 26.18 6.37 26.23 6.43 22.64 7.05 22.64 7.49 21.48 9.99 21.48 9.99 24.77 11.9 24.77 11.9 16.44 14.15 15.23 19.67 15.25 19.67 20.42 20.28 20.42 20.27 28.88 21.12 28.88 21.12 21.94 25.01 21.94 25.01 12.93 29.03 12.95 30.87 13.82 30.87 18.5 33.1 18.5 33.1 31.33 34.3 31.33 34.97 30.72 34.97 6.91 38.66 6.21 42.12 6.21 42.12 11.7 44.65 11.71 44.65 12.41 44.98 12.41 44.98 25.53 47.62 25.53 47.62 28.28 48.72 28.28 48.72 28.04 48.95 27.66 50.65 27.66 50.65 27.93 51.21 27.93 51.38 16.99 51.6 16.67 51.6 15.01 54.58 0.53 57.52 14.43 57.52 20.54 57.66 20.54 57.66 22.04 58.25 22.04 58.25 22.54 59.68 22.54 59.68 9.66 64.78 9.66 64.78 13.64 67.82 13.63 68.93 14.36 68.93 16.71 69.95 16.71 69.96 10.03 71.51 9.54 75.78 9.54 75.78 23.57 76.56 23.55 76.56 21.02 76.95 21.02 76.95 20.57 78.77 20.57 78.76 15.7 81.3 15.68 81.3 19.02 82.59 19.02 82.59 18.93 86.74 18.45 87.5 18.59 87.5 19.12 91.28 19.19 91.91 19.29 91.91 26.57 92.58 26.57 92.55 27.07 93.84 27.04 93.85 11.34 99.01 11.39 99.01 15.33 101.5 15.37 101.55 29.02 103.7 29.01 103.71 27.68 107 27.68 107.14 19.91 107.4 19.64 107.42 18.3 109.58 2.19 112.18 17.88 112.18 22.94 112.57 22.94 112.57 24.17 113.02 24.17 113.02 26.27 113.53 26.27 113.56 32.42 114.44 32.4 114.44 31.34 115.61 31.04 116.13 31.04 116.13 30.59 116.84 30.59 116.83 29.43 117.74 28.49 119.08 28.47 119.06 27.08 120.24 26.68 124.23 26.64 124.17 33.05 128.09 33.05 128.07 25.68 128.57 25.68 128.57 24.69 130.28 23.39 130.28 22.49 133.6 22.25 133.61 21.55 137.07 21.24 137.05 21.84 140.95 21.94 141.42 22.15 141.45 28.13 141.97 28.13 141.97 28.46 143.55 28.46 143.51 35.74" />
            </svg>
            <svg className="bf-tzc__skyline2" viewBox="0 0 500 80">
                <path fill="white" d="M43.63,616.86h514.9V613h-.47v-9.54h-4v-1.16H550.2v1.16h-2.67v1.27h-2.16V604h-6.78v-1h-5.54v-.85h-2.53v-.85h-2.59v.85h-3.75v1.15h-7v3.78h-4v-2h-2.82v-1.4h-1.8v-1.34h-4.47v1.34H502.7V601h-3.17v-1h-.86v-.61H495V600h-1.58v.73h-4.33v-4.2h-6.85v7.91h-9.65v-1h-1.45v-1.53H468.4v.61h-.87v.92h-1.3v3.1h-3.46v-2.31h-4.61v-4.63h-.72v-3.71h-1.66v-.91h-2.66v.91h-2.89v3.71h-1v-1.46h-1.37v5.36h-1.94V593.21H435.39v2.19h-4.47v4.25h-1.46v1.83h-1.57v-4.32h-.72v4.32h-1.95v-2.13H422.7v-1.83h-7.21v2h-2.23v4h-1.73V594.6h-1.66v-1.09h-3.6v1.09H404v6.51h-2.52v1.4H397V604h-1.22v-1h-3v-1.28h1.3v-6.93H381v2.07h-5.33v1.58h-5v1.82h-7.35V569.35H346.88v15.46H345v7.3h-7.07v10.1h-2.3V574.83h-2.17v-3.89H319.35v8.64H297v-1.83h-.28v-2.43h-5.63v2.43h-.57v1.83h-1.59v2.67h-1v4H275.24V605h-1.37v-1h-3.46v-.48h-9.3V604h-1.66v2.13h-2.52V593.69H243.81v9.13h-1.58v1.76h-1.16v.85h-1.22v-.54h-2.38v.54h-1.08v-6.81H234.8v-2.86h-3.31v-3.29h-1.23v-.73H229v-.54h-5.19v.54h-3.17v.73H219V605h-5.69v3.95h-2V604h-8.5V594h-2.81v-1h-8.44v1h-4.54v11.86h-1.81l-1.22-34.49a4.58,4.58,0,0,0,2.6-4.27,4.52,4.52,0,0,0-2.91-4.38l-.91-25.61-1.26,25.58a4.52,4.52,0,0,0-3,4.41,4.57,4.57,0,0,0,2.56,4.25l-1.45,29.5-1.33-1.2-3.75-.21v-1.31h-.29c-.87-1.86-3.54-3.22-6.7-3.22s-5.83,1.36-6.69,3.22h-.3v3.17H155v-5.44h-1.44v-2.05h-5.91v2.05h-1.59v-1.25a2.65,2.65,0,0,0,1.71-2.19c0-1.61-2.2-2.9-4.91-2.9s-4.9,1.29-4.9,2.9a2,2,0,0,0,.4,1.15h-5.13v1.7h-13v-2.06h-9.8v1.21h-9.66v1.87h-1.3v-1.75H92.89V596l-3.6,1.6V607H85.54v2.79H70.12V613H43.63Z" transform="translate(-43.63 -537.11)" />
            </svg>
            <div className="bf-tzc__main l-column">

                <div className="l-column">
                    <div className="bf-tzc__city-list l-col-row">
                        {cities}
                    </div>
                    <div className="bf-tzc__search l-col-row">
                        <svg className="icon-add" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.31 10.31"><path d="M297.17,415.33a5.16,5.16,0,1,0,5.16,5.15A5.16,5.16,0,0,0,297.17,415.33Zm2.61,5.91h-1.87v1.91a.72.72,0,0,1-1.44,0v-1.91h-1.85a.6.6,0,1,1,0-1.2h1.85V418a.72.72,0,0,1,1.44,0V420h1.87a.61.61,0,1,1,0,1.2Z" transform="translate(-292.02 -415.33)" /></svg>
                        <AutoComplete
                            config={config}
                            onControlChange={(e, v) => { this.handleTimeZoneChange(v) }}
                            className="bf-tzc-ac"
                            placeholder="Search for city..."
                            title="Add new time"
                        />
                    </div>

                    <div className="l-col-row--expand --relative">
                        <h1 className="bf-tzc__title">Compare time zones</h1>

                    </div>

                    <ContentScroller className="bf-tzc__content-scroller" visible={locations.filter(x => x.isVisible).length === 0}>
                        <div className="bf-tzc__clock-list l-row" onClick={(e) => { this.handleClick(e) }} >
                            {locations.map((x, i) => {

                                let timeLabel = time.toLocaleString([], {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    timeZone: x.timezone
                                })
                                let localDate = new Date(time.toLocaleString('en-US', { timeZone: x.timezone }))
                                let localMinute = localDate.getHours() * 60 + localDate.getMinutes()
                                document.documentElement.style.setProperty('--delay', -localMinute / 1440 + 's');
                                return <div className={`bf-tzc__clock-main ${!x.isVisible ? 'hidden' : ''}`} data-id={x.id} key={i} onAnimationEnd={this.handleAnimationEnd}>
                                    <div className="bf-tzc__clock-wrapper">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.31 10.31" className="icon-remove"><path data-id={x.id} d="M297.17,415.33a5.16,5.16,0,1,0,5.16,5.15A5.16,5.16,0,0,0,297.17,415.33Zm2.58,5.91H294.6a.61.61,0,1,1,0-1.2h5.15a.61.61,0,1,1,0,1.2Z" transform="translate(-292.02 -415.33)" /></svg>

                                        <Clock
                                            className=""
                                            hour={localDate.getHours()}
                                            min={localDate.getMinutes()}
                                            sec={localDate.getSeconds()}
                                            registerUpdate={(fn) => {
                                                this.clockUpdate.push(fn)
                                            }}
                                            unregisterUpdate={(fn) => {
                                                this.clockUpdate = this.clockUpdate.filter(item => item !== fn)
                                            }}
                                        />
                                        <div className="bf-tzc__clock-label-info">
                                            <div className="bf-tzc__clock-label">{x.name}</div>
                                            <div className="bf-tzc__clock-label">{timeLabel}</div>
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>
                    </ContentScroller>


                    <Scroller onChange={this.handleScrollerChange} className={locations.filter(x => x.isVisible).length === 0 ? "hidden" : ""} />
                    <div className={`bf-tzc__offset-label ${locations.filter(x => x.isVisible).length === 0 ? "hidden" : ""}`}>{this.state.offsetLabel}</div>
                    <div className="l-col-row--expand">

                    </div>

                </div>


            </div>
        </div>
    }
}


class Location {
    id: string
    name: string
    timezone: string
    isVisible: boolean = true;
    isMounted: boolean = true;
    constructor(name: string, timezone: string) {
        this.name = name;
        this.timezone = timezone;
        this.id = uuidv4()
        this.isVisible = true;
        this.isMounted = true;
    }
}

export default TimeZoneConverter