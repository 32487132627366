import React from "react";
import "./Styles/AutoComplete.css"

export interface IDictionary {
    [index: string]: string;
}

export interface IControlConfig {
    TypeLabel: string
    ControlCode: string
    DataLabelField: string
    DataValueField: string
    Visible: boolean
    DataSource: []
}

export interface IControlProps {
    config?: IControlConfig
    context?: FormCreatorContext
    onControlChange?: (e: React.ChangeEvent<HTMLElement> | null, value: any) => void
    value?: any
}

export type InitDataType = () => {
    get: (q: string) => object[]
}
export type FormCreatorContext = {
    InitData: InitDataType
    OnControlChange: (controlConfig: IControlConfig, value: string) => void
    FormValues: { [key: string]: any }
}

interface AutoCompleteState {
    optionsVisible: boolean,
    options: [],
    label: string,
    selectedIndex: number
    placeholder: string
}

class AutoComplete extends React.Component<IControlProps & React.HTMLAttributes<HTMLElement>> {
    state: AutoCompleteState;
    ref: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props)
        this.state = { options: [], optionsVisible: false, label: '', selectedIndex: -1, placeholder: '' }
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

    }

    handleKeyDown(e: KeyboardEvent) {

        if (this.state.optionsVisible) {
            if (e.code === 'Escape') {
                e.preventDefault();
                this.setState({
                    optionsVisible: false
                })
            }
            if (e.code === 'Enter' && this.state.selectedIndex > -1) {
                e.preventDefault();
                const data: IDictionary = this.state.options[this.state.selectedIndex];
                if (data != null) {
                    this.props.onControlChange!(null, data);
                }
                this.setState({
                    label: '',
                    optionsVisible: false
                })

            }
            if (this.state.options.length > 1) {
                if (e.code === 'ArrowDown') {
                    e.preventDefault();
                    if (this.state.selectedIndex > this.state.options.length - 2) return;
                    this.setState({
                        selectedIndex: this.state.selectedIndex + 1
                    })

                }
                if (e.code === 'ArrowUp') {
                    e.preventDefault();
                    if (this.state.selectedIndex < 1) return;
                    this.setState({
                        selectedIndex: this.state.selectedIndex - 1
                    })

                }
            }
        }
    }


    handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.onControlChange!(e, e.target.value);
    }

    handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const index = (e.target as HTMLDivElement).getAttribute('data-index');
        const data = this.state.options[parseInt(index!)];
        if (data != null) {
            this.props.onControlChange!(null, data);
        }
        this.setState({
            options: [],
            optionsVisible: false,
            label: ''
        })

    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, false);
        document.addEventListener('keydown', this.handleKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, false);
        document.removeEventListener('keydown', this.handleKeyDown, false);
    }

    deBounceTimer: any;
    deBouncer(fn: () => void, t: number) {
        clearTimeout(this.deBounceTimer);
        this.deBounceTimer = setTimeout(() => {
            fn()
        }, t);
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            label: e.target.value
        })

        this.deBouncer(() => {
            if (e.target.value.length > 2) {
                const call = async () => {

                    const res = await fetch(process.env.REACT_APP_API_URI + 'api/find-city/?input=' + e.target.value)
                    const result = await res.json();

                    const d1: { [key: string]: any } = result;
                    const d2: [] = d1.predictions;

                    const data: IDictionary[] = [];
                    d2.forEach((d3: { [key: string]: any }) => {
                        data.push({ Value: d3.place_id, Label: d3.description });
                    })

                    this.setState({
                        options: data,
                        optionsVisible: true,
                        selectedIndex: data.length === 1 ? 0 : -1
                    })
                }
                call();
            }
        }, 300)


    }

    handleMouseEnter(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        let element = e.target as HTMLElement
        this.setState({
            selectedIndex: element.getAttribute('data-index')
        })
    }

    handleClickOutside(e: MouseEvent) {
        if (this.state.optionsVisible) {
            let clickInside = false;
            if (this.ref.current && this.ref.current.contains(e.target as HTMLElement)) {
                clickInside = true;
            }
            if (!clickInside) {
                this.setState({
                    optionsVisible: false
                })

            }
        }
    }
    renderOptions() {

        return <React.Fragment>
            {this.state.options.map((d: IDictionary, index) => {
                return <div
                    className={this.state.selectedIndex === index ? 'active' : ''}
                    key={index}
                    data-index={index}
                    onMouseEnter={(e) => { this.handleMouseEnter(e); }}
                >{d[this.props.config!.DataLabelField]}

                </div>
            })}

        </React.Fragment>
    }

    render() {
        return (
            <>
                {this.state.optionsVisible && <div className="bf-ac-ly"></div>}
                <div className={this.props.className || "bf-ac-c"} ref={this.ref}>
                    <input
                        type="text"
                        autoComplete="my-field-name1"
                        onChange={(e) => { this.handleInputChange(e) }}
                        onFocus={(e) => { this.handleInputChange(e) }}
                        placeholder={this.props.placeholder}
                        className={this.props.className}
                    />
                    {this.state.optionsVisible &&
                        <div className="bf-ac-rc">
                            <div

                                className="bf-ac-r"
                                onClick={(e) => { this.handleClick(e); }}

                            >
                                {this.renderOptions()}
                            </div>
                        </div>
                    }

                </div>
            </>
        )
    }
}

export { AutoComplete }
