import React, { ReactNode } from "react";
import './Styles/ContentScroller.css'


interface ContentScrollerProps {
    children: React.ReactNode,
    visible: boolean,
    className?: string
}

export class ContentScroller extends React.Component<ContentScrollerProps> {
    innerRef: React.RefObject<HTMLDivElement>
    outerRef: React.RefObject<HTMLDivElement>
    controllerRef: React.RefObject<HTMLDivElement>
    mouseDownX: number = 0;
    aspect: number = 0;
    offsetPercentTemp: number = 0;
    offsetPercent: number = 0;
    overflowPixel: number = 0;
    overflowPercent: number = 0;
    controllerGap: number = 0;
    controllerWidth: number = 0;

    constructor(p: ContentScrollerProps) {
        super(p)
        this.innerRef = React.createRef();
        this.outerRef = React.createRef();
        this.controllerRef = React.createRef();

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.updateController();

        }, 1000);
    }

    componentDidUpdate() {
        var t = this;
        this.updateController();

    }

    updateController() {
        if (this.innerRef.current && this.controllerRef.current && this.outerRef.current) {

            this.overflowPixel = this.innerRef.current.scrollWidth - document.body.clientWidth;
            this.overflowPercent = this.overflowPixel / document.body.clientWidth;
            this.controllerWidth = document.body.clientWidth / (1 + this.overflowPercent);
            this.controllerGap = document.body.clientWidth - this.controllerWidth;

            this.offsetPercent = 0;
            if (this.overflowPixel > 0) {
                this.offsetPercent = this.outerRef.current.scrollLeft / this.overflowPixel;
            }

            if (this.overflowPixel <= 0) {
                this.offsetPercent = 0
                this.controllerRef.current.style.left = 0 + 'px';
                this.controllerRef.current.style.width = 0 + 'px';
            } else {
                this.controllerRef.current.style.left = this.controllerGap * this.offsetPercent + 'px';
                this.controllerRef.current.style.width = this.controllerWidth + 'px';
            }

        }
    }

    handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
        if (e.target as HTMLDivElement === this.controllerRef.current!) {
            e.preventDefault();
            this.mouseDownX = e.clientX;
            document.addEventListener('mousemove', this.handleMouseMove)
            document.addEventListener('mouseup', this.handleMouseUp)
        }
    }

    handleMouseUp(e: MouseEvent) {
        document.removeEventListener('mousemove', this.handleMouseMove)
        document.removeEventListener('mouseup', this.handleMouseUp)
        this.offsetPercent = this.offsetPercentTemp
    }

    handleMouseMove(e: MouseEvent) {
        e.preventDefault();
        if (this.controllerRef.current && this.innerRef.current) {

            this.offsetPercentTemp = this.offsetPercent - ((this.mouseDownX - e.clientX) / this.controllerGap)

            if (this.offsetPercentTemp < 0) {
                this.offsetPercentTemp = 0
            } else if (this.offsetPercentTemp > 1) {
                this.offsetPercentTemp = 1;
            }
            this.controllerRef.current.style.left = (this.controllerGap * this.offsetPercentTemp) + 'px';
            if (this.outerRef.current) {
                this.outerRef.current.scrollLeft = (this.overflowPixel * this.offsetPercentTemp)
            }
        }
    }

    render() {
        return <div className={`bfc-co-sc ${this.props.className ? this.props.className : ""}`}>
            <div ref={this.outerRef} className="bfc-co-sc__container">
                <div ref={this.innerRef}>
                    {this.props.children}
                </div>
            </div>
            <div onMouseDown={this.handleMouseDown} className={`bfc-co-sc__track ${this.props.visible ? "bfc-co-sc__track--hidden" : ""}`}>
                <span ref={this.controllerRef} className="bfc-co-sc__scoller"></span>
            </div>

        </div >
    }

}