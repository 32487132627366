import React from "react";
import "./Styles/Clock.css";

interface ClockProps {
    hour: number,
    min: number,
    sec: number,
    className: string,
    registerUpdate: (fn: () => void) => void,
    unregisterUpdate: (fn: () => void) => void
}
interface ClockState {
    sec: number,
    secCount: number
}


export class Clock extends React.Component<ClockProps> {
    state: ClockState
    timer?: NodeJS.Timer
    constructor(props: ClockProps) {
        super(props);
        this.state = {
            sec: (new Date()).getSeconds(),
            secCount: (new Date()).getSeconds()
        }
        this.updateSecond = this.updateSecond.bind(this);
    }

    componentDidMount() {
        this.props.registerUpdate(this.updateSecond)
    }

    componentWillUnmount() {
        this.props.unregisterUpdate(this.updateSecond)
    }

    updateSecond() {
        this.setState({
            sec: (new Date()).getSeconds(),
            secCount: this.state.secCount + 1
        })
    }

    componentDidUpdate(prevProps: ClockProps) {
        if (prevProps.min !== this.props.min || prevProps.hour !== this.props.hour) {
            this.setState({ secCount: (new Date()).getSeconds() })
        }
    }

    render(): React.ReactNode {
        const min = (this.props.min + (Math.floor(this.state.secCount / 60)))
        const minAngle = min * 6;
        const hour = (this.props.hour * 60 + this.props.min + (Math.floor(this.state.secCount / 60))) / 60
        const hourAngle = hour * 30
        let isDay = hour > 8 && hour < 20 ? true : false

        return <svg height="240" width="240" viewBox="-10 -10 220 220" className={`${this.props.className} ${!isDay && 'bf-tzc__clock--night'}`}>
            <defs>
                <filter id="blur">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                </filter>
                <filter id="gaussian-blur">
                    <feGaussianBlur stdDeviation="1 0" />
                </filter>
                <clipPath id="mask">
                    <circle cx="100" cy="100" r="93" />
                </clipPath>
            </defs>

            <circle cx="110" cy="110" r="90" style={{ fill: 'black' }} filter="url(#blur)" />
            <circle className="bfc-clock__background" cx="100" cy="100" r="99" fill="#e5e5e5" />


            <line className="bfc-clock__face-control" x1="170" y1="100" x2="190" y2="100" strokeWidth={4} />
            <line className="bfc-clock__face-control" x1="170" y1="100" x2="190" y2="100" strokeWidth={4} transform="rotate(90,100,100)" />
            <line className="bfc-clock__face-control" x1="170" y1="100" x2="190" y2="100" strokeWidth={4} transform="rotate(180,100,100)" />
            <line className="bfc-clock__face-control" x1="170" y1="100" x2="190" y2="100" strokeWidth={4} transform="rotate(270,100,100)" />


            <line className="bfc-clock__face-control" x1="180" y1="100" x2="190" y2="100" strokeWidth={1} transform="rotate(30,100,100)" />
            <line className="bfc-clock__face-control" x1="180" y1="100" x2="190" y2="100" strokeWidth={1} transform="rotate(60,100,100)" />
            <line className="bfc-clock__face-control" x1="180" y1="100" x2="190" y2="100" strokeWidth={1} transform="rotate(120,100,100)" />
            <line className="bfc-clock__face-control" x1="180" y1="100" x2="190" y2="100" strokeWidth={1} transform="rotate(150,100,100)" />
            <line className="bfc-clock__face-control" x1="180" y1="100" x2="190" y2="100" strokeWidth={1} transform="rotate(210,100,100)" />
            <line className="bfc-clock__face-control" x1="180" y1="100" x2="190" y2="100" strokeWidth={1} transform="rotate(240,100,100)" />
            <line className="bfc-clock__face-control" x1="180" y1="100" x2="190" y2="100" strokeWidth={1} transform="rotate(300,100,100)" />
            <line className="bfc-clock__face-control" x1="180" y1="100" x2="190" y2="100" strokeWidth={1} transform="rotate(330,100,100)" />

            <polygon className="bfc-clock__face-control" points="95 110, 98, 35, 102, 35, 105, 110" transform={`rotate(${hourAngle},100,100)`} />
            <polygon className="bfc-clock__face-control" points="97 120, 98, 20, 102, 20, 103, 120" transform={`rotate(${minAngle},100,100)`} />
            <polygon className="bfc-clock__face-control" points="99 130, 100 30, 101 130" transform={`rotate(${this.state.sec * 6},100,100)`} />

            <circle cx="100" cy="100" r="2" style={{ fill: 'white' }} />

            {/* <circle cx="50" cy="50" r="50" fill="white" filter="url(#blur)" style={{ opacity: 0.2 }} clipPath="url(#mask)" ></circle>
            <circle cx="-50" cy="-50" r="150" fill="white" clipPath="url(#mask)" style={{ opacity: 0.1 }}  ></circle> */}
            <circle className="bfc-clock__face-reflection" cx="300" cy="300" r="270" clipPath="url(#mask)" style={{ opacity: 0.05 }}  ></circle>
            <circle cx="100" cy="100" r="98" stroke="black" strokeWidth="3" fill="transparent" />

        </svg >
    }
}